import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import './Contact.css';

import { MdEmail } from "react-icons/md";

const Contact = ({ title }) => (
  <section
    id="contact"
    className="d-flex justify-content-center align-items-center section">
    <Container className="section-container contact-container d-flex align-items-center flex-column">
      <Row>
        <Col className="text-center title">
          <h1>{title}</h1>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <MdEmail className="features-icons mb-3" color="#343A40" size="60"/>
          <div className="contact">
            <a href="mailto:contact@dancortes.com" target="_blank" rel="noreferrer">contact@dancortes.com</a>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Contact
