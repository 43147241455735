const { MdList, MdPalette, MdDevices, MdSync, MdLanguage } = require("react-icons/md");

module.exports = {
  title: "My Club List",
  keywords: [
    "My Club List",
    "Shopping List",
    "Lista de Compras",
    "Listas de Compras",
    "Listas",
    "Compras",
  ],
  description: "App - My Club List",
  author: "@_dancortes",
  trackingId: "UA-174201355-1",
  defaultLang: "pt-BR",
  siteUrl: "https://myclublist.com",
  langTextMap: {
    "pt-BR": "Português (Brasileiro)",
    en: "English",
    es: "Español",
  },
  icons: {
    MdList, MdPalette, MdDevices, MdSync, MdLanguage
  }
}
