import React from "react"
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row } from "react-bootstrap";
import './Screenshots.css';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Screenshots = ({ screenshots }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(filter:
          { extension: {in: ["jpg", "png", "jpeg"]}, relativePath: {regex: "/^device.*/"} },
            sort: {fields: base}) {
          edges {
            node {
              base
              childImageSharp {
                fixed(width: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
  `)

const screenshotImages = screenshots.map(screenshot => {
  const screenshotImageEdge = data.allFile.edges.filter(
    edge => edge && edge.node && edge.node.base === screenshot.filename)
  const {node: { childImageSharp } } = screenshotImageEdge[0]
  const screenshotImage = {...screenshot, childImageSharp}
  return screenshotImage
})

  return (
    <section
      id="screenshots"
      className="section-screenshots d-flex justify-content-center align-items-center">
      <Container className="section-container">
        <Row className="d-flex justify-content-center">
          <Carousel
            autoPlay
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            useKeyboardArrows
            className="screenshots-carousel"
            width="300px">
            {
              screenshotImages.map(screenshot => (
                <div key={screenshot.filename}>
                  <Img
                    fixed={screenshot.childImageSharp.fixed}
                    alt={screenshot.alt} />
                </div>
              ))
            }
          </Carousel>
        </Row>
      </Container>
    </section>
  )
}

export default Screenshots
