import React from "react"
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Home from "../components/Home"
import Platforms from "../components/Platforms"
import Features from "../components/Features"
import Screenshots from "../components/Screenshots"
import Contact from "../components/Contact"

const IndexPage = ({ data, pageContext: { langKey, defaultLang, langTextMap } }) => {
  const {
    site: {
      siteMetadata: { keywords, description },
    },
    allMarkdownRemark: { nodes }
  } = data

  const headerNodes = nodes.filter(node => node.frontmatter.content === "Header")
  const {frontmatter: { menuItems }} = headerNodes[0]

  const footerNodes = nodes.filter(node => node.frontmatter.content === "Footer")
  const {frontmatter: { copyright, cookiePolicyText, privacyPolicyText }} = footerNodes[0]

  const homeNodes = nodes.filter(node => node.frontmatter.content === "Home")
  const homeData = homeNodes[0]

  const platformNodes = nodes.filter(node => node.frontmatter.content === "Platforms")
  const platformsData = platformNodes[0]

  const featureNodes = nodes.filter(node => node.frontmatter.content === "Features")
  const featuresData = featureNodes[0]

  const screenshotNodes = nodes.filter(node => node.frontmatter.content === "Screenshots")
  const screenshotsData = screenshotNodes[0]

  const contactNodes = nodes.filter(node => node.frontmatter.content === "Contact")
  const contactData = contactNodes[0]

  return (
    <Layout
      menuItems={menuItems}
      langTextMap={langTextMap}
      langKey={langKey}
      defaultLang={defaultLang}
      copyright={copyright}
      cookiePolicyText={cookiePolicyText}
      privacyPolicyText={privacyPolicyText} >
      <SEO
        lang={langKey}
        title="Home"
        keywords={keywords}
        description={description} />
      <Home
        altHomeImage={homeData.frontmatter.image.alt}
        homeImageFileName={homeData.frontmatter.image.filename} />
      <Platforms
        title={platformsData.frontmatter.title}
        imagePlatforms={platformsData.frontmatter.imagePlatforms} />
      <Features
        title={featuresData.frontmatter.title}
        features={featuresData.frontmatter.features} />
      <Screenshots
        screenshots={screenshotsData.frontmatter.screenshots} />
      <Contact
        title={contactData.frontmatter.title} />
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery($langKey: String!) {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    )
    {
      nodes {
        frontmatter {
          content
          menuItems {
            label
            section
          }
          cookiePolicyText
          copyright
          privacyPolicyText
          image {
            alt
            filename
          }
          title
          imagePlatforms {
            alt
            filename
          }
          features {
            description
            iconName
            title
          }
          screenshots {
            alt
            filename
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

IndexPage.defaultProps = {
  pageContext: {
    langKey: "pt-BR",
    defaultLang: "pt-BR",
    langTextMap: {},
  },
};

export default IndexPage
