import React from "react"
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

const Home = ({altHomeImage, homeImageFileName}) => {

  const {images} = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: {extension: {in: ["jpg", "png", "jpeg"]}}, sort: {fields: name}) {
          edges {
            node {
              base
              childImageSharp {
                fluid(maxWidth: 1900, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
  `)

  const homeImageEdge = images.edges.filter(edge => edge.node.base === homeImageFileName)
  const {node: { childImageSharp } } = homeImageEdge[0]

  /*
  const {homeImage} = useStaticQuery(
    graphql`
      query {
        homeImage: file(relativePath: {eq: "home.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 1900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
  */
  return (
    <section
      style={{backgroundColor: '#e57373'}}
      id="home"
      className="d-flex justify-content-center align-items-center section">
      <Img className="img-fluid" fluid={childImageSharp.fluid} style={{width: 'inherit', height: 'inherit'}}/>
    </section>
  )
}

export default Home
