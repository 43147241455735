import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import './Platforms.css';

const Platforms = ({ title, imagePlatforms }) =>  {

  const {images} = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: {extension: {in: ["jpg", "png", "jpeg"]}}, sort: {fields: base}) {
          edges {
            node {
              base
              childImageSharp {
                fixed(width: 320) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
  `)

  const storeImages = imagePlatforms.map(image => {
    const storeImageEdge = images.edges.filter(edge => edge.node.base === image.filename)
    const {node: { childImageSharp } } = storeImageEdge[0]
    const storeImage = {...image, childImageSharp}
    return storeImage
  })

  /*
  const { googlePlayImage, appleStoreImage, webImage } = useStaticQuery(
    graphql`
      query {
        appleStoreImage: file(relativePath: {eq: "app_store.png"}) {
          childImageSharp {
            fixed(width: 320) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        googlePlayImage: file(relativePath: {eq: "google-play.png"}) {
          childImageSharp {
            fixed(width: 320) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        webImage: file(relativePath: {eq: "web.png"}) {
          childImageSharp {
            fixed(width: 320) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `)
  */

  return (
    <section
      id="platforms"
      className="d-flex justify-content-center align-items-center section-platforms">
      <Container className="section-container">
        <Row>
          <Col className="text-center pb-5 mb-5">
            <h1>{title}</h1>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          {storeImages.map(storeImage =>
            <div key={storeImage.filename} className="store-images">
              <Img
                fixed={storeImage.childImageSharp.fixed}
                alt={storeImage.alt}/>
            </div>
          )}

          {/*
          <div className="store-images">
              <Img fixed={appleStoreImage.childImageSharp.fixed} />
          </div>
          <div className="store-images">
            <Img fixed={googlePlayImage.childImageSharp.fixed} />
          </div>
          <div className="store-images">
            <Img fixed={webImage.childImageSharp.fixed} />
          </div>
           */}
        </Row>
      </Container>
    </section>
  )
}

export default Platforms
