import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import './Features.css';
import siteConfig from "../../config/site";

const Features = ({ title, features }) => (
  <section
    style={{backgroundColor: '#F8F9FA'}}
    id="features"
    className="d-flex justify-content-center align-items-center section-features">
    <Container className="section-container features-container d-flex align-items-center flex-column">
      <Row>
        <Col className="text-center title">
          <h1>{title}</h1>
        </Col>
      </Row>
      {features.map(feature => {
        const Icon = siteConfig.icons[feature.iconName]
        return (
          <div key={feature.title}>
            <Row>
              <Col className="text-center">
                <div className="rounded-circle bg-dark d-inline-block p-2 mb-2">
                  <Icon className="features-icons" color="#F8F9FA"/>
                </div>
                <h2>{feature.title}</h2>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <p>{feature.description}</p>
                <hr className="mb-5"/>
              </Col>
            </Row>
          </div>
        )
      })}
    </Container>
  </section>
)

export default Features
